import {
  OnTrackStatus,
  PackageInstanceCard,
  PackageInstanceCardCyclesInner,
  PackageInstancePublic,
  PackageInstancePublicCyclesInner,
} from "@practice/sdk";
import { PackageSchedulerType } from "api-services/definitions/package-instances";
import { capitalize, sortBy } from "lodash";
import compact from "lodash/compact";

import { displayNameFromContact } from "@lib/contacts";
import { AccountType } from "@lib/data/schemas/account";
import {
  PackageFrequencyTypeType,
  PackageTimeType,
  PackageType,
} from "@lib/data/schemas/packages";
import { SchedulerType } from "@lib/data/schemas/scheduler";
import { TaxTypeType } from "@lib/data/schemas/taxType";
import { getCurrentCycle } from "@lib/models/package-instances/utils";
import { findAutoChargeWeekdayValue } from "@lib/models/packages/utils/transform-input-form";
import { getPaymentOptionTitle } from "@lib/packages/payment-options";
import { displayItemAmountString } from "@lib/products";
import { getOrdinal } from "@lib/utils";
import pluralHelper from "@lib/utils/pluralHelper";

import PathIcon from "@components/Icons/PathIcon";
import PaymentOptionOneTimeIcon from "@components/Icons/PaymentOptionOneTimeIcon";

import { GetPackagePaymentOptionType } from "../types";

export const getPackagePaymentOptionProps: GetPackagePaymentOptionType = (
  { type, amount, currency, taxTypeId, splits },
  isUsagePackage,
  usageInvoicingMethod,
  subscriptionAmount
) => {
  const dueOnZeroSplit = splits[0];
  const { unit, amount: splitZeroAmount } = dueOnZeroSplit;
  const Icon = type === "one-time" ? PaymentOptionOneTimeIcon : PathIcon;
  const isAuto = usageInvoicingMethod === "auto";
  const isSubscription = usageInvoicingMethod === "subscription";
  const usageSubtitle = `${
    isAuto ? "Automatic" : isSubscription ? "Subscription" : "Manual"
  } invoicing`;
  const subtitle = isUsagePackage
    ? usageSubtitle
    : `${splitZeroAmount}${unit} at booking`;
  return {
    title: getPaymentOptionTitle(type, isUsagePackage),
    subtitle,
    icon: <Icon className="h-6 w-6" />,
    amount,
    currency,
    taxTypeId,
    splits,
    isUsagePackage,
    subscriptionAmount,
  };
};

/**
 * Return the total number of appointments in a package or package instance
 * based on the items or the totalSessions field
 * */
type FrequencyCopiesValuesType = "abbreviation" | "singular" | "intervals";
type FrequencyCopiesType = Record<
  PackageFrequencyTypeType,
  Record<FrequencyCopiesValuesType, string>
>;
export const frequencyCopies: FrequencyCopiesType = {
  weeks: {
    abbreviation: "wk",
    singular: "week",
    intervals: "weekly",
  },
  months: {
    abbreviation: "mo",
    singular: "month",
    intervals: "monthly",
  },
};

export const getTotalAvailable = (
  data: PackageInstanceCard | PackageInstancePublic | PackageType,
  currentCycleOnly?: boolean,
  fromCycle?:
    | PackageInstanceCardCyclesInner
    | PackageInstancePublicCyclesInner
    | null
) => {
  if ("packageId" in data) {
    const cycles = data?.cycles ? data.cycles : [];
    const fromCycleIndex = cycles.findIndex((cycle) => cycle === fromCycle);
    if (fromCycleIndex !== -1) {
      const currentCycle = cycles[fromCycleIndex];
      return currentCycle?.totalAvailable ?? data?.totalAvailable ?? 0;
    }

    if (currentCycleOnly) {
      const currentCycle = getCurrentCycle(data);
      return currentCycle?.totalAvailable ?? data?.totalAvailable ?? 0;
    }

    return data?.totalAvailable ?? 0;
  } else {
    return data?.frequency?.total ?? 0;
  }
};

const getFrequencyType = (
  data: PackageInstanceCard | PackageInstancePublic | PackageType
) => {
  if ("packageId" in data) {
    return data?.frequency ?? "months";
  }
  return data?.frequency?.type ?? "months";
};

export const countPackageAppointments = (
  data: PackageInstanceCard | PackageInstancePublic | PackageType,
  frequencyCopiesType: FrequencyCopiesValuesType | null = "abbreviation",
  label: string | undefined = undefined,
  currentCycleOnly?: boolean,
  fromCycle?:
    | PackageInstanceCardCyclesInner
    | PackageInstancePublicCyclesInner
    | null
) => {
  const isPackageInstance = "packageId" in data;
  if (data.packageType === "flexible") {
    if (isPackageInstance) return data.totalAvailable;
    return "Flexible";
  }
  const isContentTypeTime = data?.contentType === "time";
  const isReccuringBased = data?.packageType === "recurring";
  const timeType = data?.timeType || "hours";
  const timeTypeSingular = timeType.substring(0, timeType.length - 1);

  if (isReccuringBased) {
    const frequency = getFrequencyType(data);
    const frequencyCopy = frequencyCopies[frequency];
    const isFrequencyCopiesTypeNull = frequencyCopiesType === null;

    const frequencyCopyValue = isFrequencyCopiesTypeNull
      ? ""
      : frequencyCopy[frequencyCopiesType];

    const frequencyTotal = getTotalAvailable(
      data,
      currentCycleOnly,
      fromCycle
    )!;

    const isFrequencyCopyIntervals = frequencyCopiesType === "intervals";
    const totalType = isContentTypeTime ? timeTypeSingular : "appt";
    const value = pluralHelper(frequencyTotal, totalType);

    if (isFrequencyCopiesTypeNull) {
      return frequencyTotal;
    }

    return isFrequencyCopyIntervals
      ? `${frequencyTotal} ${frequencyCopyValue}`
      : `${value}/${frequencyCopyValue}`;
  }

  if (isContentTypeTime) {
    if (isPackageInstance)
      return pluralHelper(data?.totalAvailable ?? 0, timeTypeSingular);

    return pluralHelper(data?.totalSessions ?? 0, timeTypeSingular);
  }

  if (data?.distributeSessions) {
    if (!isPackageInstance) return data?.totalSessions ?? 0;
    return data.totalAvailable;
  }

  const quantityItems = data?.items?.map((item: any) => item.quantity) || [];

  const total = quantityItems?.reduce<number>((acc, quantity) => {
    return acc + quantity;
  }, 0);

  if (label) {
    return pluralHelper(total || 0, label);
  } else {
    return total;
  }
};

export const getUniquePackageAccountNames = (
  accounts: Partial<AccountType>[]
) => {
  const names = accounts.map((a: any) => displayNameFromContact(a, true));
  const uniqueNames = [...new Set(names)];
  return uniqueNames.join(", ");
};

export const getRemainingAppointments = (
  scheduler: PackageSchedulerType
): number => {
  const quantity = scheduler?.quantity || 0;
  const completed = scheduler?.appointments?.length || 0;
  const remaining = quantity - completed;
  return Math.max(0, remaining);
};

export const filterDuplicateSchedulersByPackageInstanceId = (
  packageInstanceId: string,
  packageSchedulers: PackageSchedulerType[]
) => {
  const groupedSchedulers: {
    [key: string]: PackageSchedulerType[];
  } = {};
  packageSchedulers?.forEach((scheduler) => {
    if (groupedSchedulers[scheduler.id]) {
      groupedSchedulers[scheduler.id].push(scheduler);
    } else {
      groupedSchedulers[scheduler.id] = [scheduler];
    }
  });

  const filtered = Object.keys(groupedSchedulers).map((key) => {
    const schedulers = groupedSchedulers[key];
    if (schedulers.length === 1) {
      return schedulers[0];
    } else {
      const scheduler = schedulers.find(
        (s) =>
          s.packageInstanceId === packageInstanceId &&
          getRemainingAppointments(s)
      );
      return scheduler || schedulers[0];
    }
  });

  return filtered;
};

/**
 * Returns the total seconds of all schedulers
 * */
export const getAllSchedulersInMinutes = (
  schedulers: PackageSchedulerType[] = []
) => schedulers?.reduce((acc, item) => acc + (item?.duration || 0), 0);

/**
 * Returns the total sessions in minutes
 * */
export const getTotalSessionsInMinutes = (
  value: number,
  type: PackageTimeType
) => (type === "hours" ? value * 60 : value);

/**
 * Returns true if the total duration of all schedulers is greater than the
 * total value
 * */
export const isDurationGreaterThenTotal = (
  schedulers: SchedulerType[],
  total: number,
  timeType: PackageTimeType
) => {
  const totalSchedulersInMinutes = getAllSchedulersInMinutes(schedulers);
  const totalSessionsInMinutes = getTotalSessionsInMinutes(
    total || 0,
    timeType || "hours"
  );

  return totalSchedulersInMinutes > totalSessionsInMinutes;
};

/**
 * Formats a substitle given a package
 * */
export const getPackageSubtitle = (
  packageItem: PackageType | PackageInstanceCard
) => {
  if (packageItem.packageType === "usage") {
    const suggestion = getSuggestedUsagePackageInfo(packageItem);
    return suggestion?.suggestedSubtitle ?? "";
  }
  return compact([
    countPackageAppointments(packageItem),
    packageItem?.packageType === "recurring" ||
    packageItem?.contentType === "time" ||
    packageItem?.packageType === "flexible"
      ? ""
      : "appointments",
  ]).join(" ");
};

export const getFlexiblePackageUnit = (packageItem: PackageType) => {
  if (packageItem.packageType !== "flexible") return "";
  return packageItem?.contentType === "sessions" ? "session" : "hour";
};

export const getPackageInstanceUnit = (
  packageInstance: PackageInstanceCard,
  recurring: boolean
) => {
  const unit =
    packageInstance.contentType === "sessions"
      ? "sessions"
      : packageInstance.timeType;

  if (packageInstance.frequency) {
    if (!recurring) return unit;
    return `${unit} per ${packageInstance.frequency.slice(0, -1)}`;
  }
  return unit;
};

export const cheapestSuggestedOption = (items: PackageType["items"]) =>
  items.length > 1 &&
  items.reduce((prev, curr) =>
    (prev.usagePricing?.amount ?? 0) < (curr.usagePricing?.amount ?? 0)
      ? prev
      : curr
  );

export const mostExpensiveSuggestedOption = (items: PackageType["items"]) =>
  items.length > 1 &&
  items.reduce((prev, curr) =>
    (prev.usagePricing?.amount ?? 0) > (curr.usagePricing?.amount ?? 0)
      ? prev
      : curr
  );

export const getSuggestedUsagePackageInfo = (
  packageObject: PackageType | PackageInstanceCard
) => {
  const hasAtLeastOneMonthly = packageObject?.items?.some(
    (item: any) => item.suggestedFrequency?.type === "months"
  );
  const suggestedAmountTotal = packageObject?.items?.reduce((acc, item) => {
    const isWeekly = item.suggestedFrequency?.type === "weeks";
    const suggestedTotal = item.suggestedFrequency?.total ?? 0;
    return (
      acc +
      (isWeekly && hasAtLeastOneMonthly ? suggestedTotal * 4 : suggestedTotal)
    );
  }, 0);
  if (suggestedAmountTotal <= 0) return null;
  const isSessions = packageObject.contentType === "sessions";
  const suggestedTimeFrame = hasAtLeastOneMonthly ? "monthly" : "weekly";
  const shortenedTimeFrame = hasAtLeastOneMonthly ? "mo" : "wk";
  const suggestionBase = `${suggestedAmountTotal} ${
    isSessions ? "appts" : "hours"
  }`;
  const suggestedSubtitle = suggestedAmountTotal
    ? `${suggestionBase}/${shortenedTimeFrame}`
    : "";
  const suggestedInfo = `${suggestionBase} suggested ${suggestedTimeFrame}`;

  return {
    suggestedAmountTotal,
    suggestedTimeFrame,
    suggestedSubtitle,
    suggestedInfo,
  };
};

export const getPackageSchedulerSuggestionInfo = (
  scheduler: PackageType["items"][0] & {
    contentType: PackageType["contentType"];
  }
) => {
  if (!scheduler.suggestedFrequency || !scheduler.contentType)
    return { suggestedSubtitle: "" };
  const suggestedFrequency = scheduler.suggestedFrequency;
  const suggestedAmount = suggestedFrequency?.total;
  const suggestedType = suggestedFrequency?.type;
  const isSessions = scheduler.contentType === "sessions";
  const suggestedTimeFrame = suggestedType === "months" ? "mo" : "wk";
  const suggestedSubtitle = suggestedAmount
    ? `${suggestedAmount} ${
        isSessions ? "appts" : "hrs"
      } suggested/${suggestedTimeFrame}`
    : "";

  return {
    suggestedAmount,
    suggestedTimeFrame,
    suggestedSubtitle,
  };
};

const getUsageInvoiceSubtitle = (
  usageInvoicing?: PackageType["usageInvoicing"]
) => {
  if (!usageInvoicing) return;
  const { dueAfterDays, method, period, autoChargeDay, cutOffDate } =
    usageInvoicing;
  const isAuto = method === "auto";
  if (isAuto) {
    return period === "months"
      ? `Charged automatically on the ${getOrdinal(
          autoChargeDay ?? 1
        )} of the month`
      : `Charged automatically on ${capitalize(
          findAutoChargeWeekdayValue(cutOffDate!, autoChargeDay!)
        )}`;
  } else if (dueAfterDays) {
    return `Pay manually on the ${getOrdinal(dueAfterDays)} of the month`;
  }
};

export const getUsagePackageInfo = (packageObject: PackageType) => {
  if (packageObject.packageType !== "usage") return null;
  const options = packageObject.paymentOptions;
  const items = packageObject.items;
  const deposit = options[0];
  const { period } = packageObject.usageInvoicing || {};
  const isMonthlyInvoicing = period === "months";
  const usageInvoiceTitle =
    deposit && `Receive ${isMonthlyInvoicing ? "monthly" : "weekly"} invoices`;

  const usageInvoiceSubtitle = getUsageInvoiceSubtitle(
    packageObject.usageInvoicing
  );

  const usageDepositTitle = deposit
    ? `${displayItemAmountString(
        deposit.splits?.reduce((acc, item) => acc + item.total, 0) ??
          deposit.amount,
        deposit.currency
      )} due at booking`
    : "Deposit not required";
  const usageDepositSubtitle = deposit ? "Taxes included" : "";

  const suggestion = getSuggestedUsagePackageInfo(packageObject);
  const usageSuggestedSubtitle = "With various durations";

  const usagePaymentTitle = "Pay as you go";
  const leastExpensiveOption = cheapestSuggestedOption(items);
  const expensiveOption = mostExpensiveSuggestedOption(items);

  const cheapestAmount =
    leastExpensiveOption && leastExpensiveOption.usagePricing?.amount;
  const cheapestCurrency =
    leastExpensiveOption && leastExpensiveOption.usagePricing?.currency;
  const expensiveAmount =
    expensiveOption && expensiveOption.usagePricing?.amount;
  const expensiveCurrency =
    expensiveOption && expensiveOption.usagePricing?.currency;
  const areSamePrice = cheapestAmount === expensiveAmount;

  const formattedLeastExpensive = displayItemAmountString(
    cheapestAmount,
    cheapestCurrency
  );
  const formattedExpensive =
    expensiveOption &&
    displayItemAmountString(expensiveAmount, expensiveCurrency);

  const usagePaymentSubtitle = areSamePrice
    ? formattedLeastExpensive
    : `Price range: ${formattedLeastExpensive} - ${formattedExpensive}`;

  return {
    usageInvoiceTitle,
    usageInvoiceSubtitle,
    usageDepositTitle,
    usageDepositSubtitle,
    usageSuggestedTitle: suggestion?.suggestedInfo,
    usageSuggestedSubtitle,
    usagePaymentTitle,
    usagePaymentSubtitle,
  };
};

export const sortedTaxTypeOptions = (taxTypes: TaxTypeType[]) => {
  return sortBy(taxTypes, [(t) => t.name]).map((taxType) => ({
    value: taxType.id,
    label: `${taxType.name} (${taxType.percentage}%, ${
      taxType.inclusive ? "inclusive" : "exclusive"
    })`,
  }));
};

export const formatOnTrackPayload = (payload, isPackageInstance = false) => {
  const { onTrackRules, onTrackNotifications, onTrackEnabled, enableAtRisk } =
    payload ?? {};

  const {
    offTrackSessionCount,
    onTrackDaysInterval,
    allowClientView,
    atRiskSessionCount,
  } = onTrackRules ?? {};

  if (!onTrackEnabled)
    return {
      onTrackRules: null,
      onTrackNotifications: null,
      ...(isPackageInstance && { onTrackEnabled: false }),
    };

  const riskSessions = enableAtRisk ? Number(atRiskSessionCount) : null;

  const formattedRules = {
    atRiskSessionCount: riskSessions,
    offTrackSessionCount: Number(offTrackSessionCount),
    onTrackDaysInterval: Number(onTrackDaysInterval),
    allowClientView: allowClientView ?? false,
  };

  const { atRisk, offTrack } = onTrackNotifications ?? {};
  const transformToNumber = (notif) =>
    notif?.map((n) => ({
      ...n,
      afterDays: Number(n.afterDays),
    }));

  const riskNotifs = enableAtRisk ? transformToNumber(atRisk) : null;

  const formattedNotifs =
    atRisk?.length || offTrack?.length
      ? {
          atRisk: riskNotifs,
          offTrack: transformToNumber(offTrack),
        }
      : null;

  return {
    onTrackRules: formattedRules,
    onTrackNotifications: formattedNotifs,
    ...(isPackageInstance && {
      onTrackEnabled: true,
      ...(onTrackEnabled && { onTrackStatus: OnTrackStatus.OnTrack }),
    }),
  };
};
