import { putUpdateScheduler } from "api-services/definitions/schedulers";
import { api } from "api-services/endpoints";
import { capitalize } from "lodash";

import { AccountType } from "@lib/data/schemas/account";
import { AvailabilityBlockType } from "@lib/data/schemas/availability-block";
import { IntegrationsType } from "@lib/data/schemas/integrations";
import { SchedulerType } from "@lib/data/schemas/scheduler";
import { getCalendarOwner } from "@lib/utils/teams";

export const handleShareScheduler = async ({
  clientId,
  scheduler,
  userId,
}: {
  clientId: string;
  scheduler: SchedulerType;
  userId: string;
}): Promise<void | null> => {
  const currentSharedWith = scheduler.sharedWith;
  if (currentSharedWith?.includes(clientId)) return null;

  const body = {
    sharedWith: currentSharedWith
      ? [...currentSharedWith, clientId]
      : [clientId],
  };

  await api(putUpdateScheduler, {
    body,
    path: { apiVersion: "v1", userId, schedulerId: scheduler.id },
  });
};

// TODO API
export const getAvailabilitiesWithMembers = (
  availabilities: AvailabilityBlockType[],
  integrations: IntegrationsType
) => {
  return availabilities?.map((availability) => {
    return {
      ...availability,
      account: getCalendarOwner(
        integrations?.calendars,
        availability.writeCalendar?.id,
        availability.accountId
      ),
    };
  });
};

export const getSchedulersWithAccounts = (
  schedulers: SchedulerType[] | undefined,
  availabilities:
    | (AvailabilityBlockType & { account?: AccountType })[]
    | undefined,
  oid: string
) => {
  return schedulers?.map((scheduler) => {
    return {
      ...scheduler,
      account: availabilities?.find(
        (block) => block.id === scheduler.availabilityId
      )?.account,
      accounts: scheduler.roundRobin?.members
        ?.map((member) => {
          return availabilities?.find(
            (block) => block.id === member.availabilityId
          )?.account;
        })
        .filter(Boolean),
      communication: scheduler.communication?.map((c) => {
        return {
          ...c,
          memberId: c.value == "zoom-call" ? c.memberId ?? oid : undefined,
        };
      }),
    };
  });
};

export const getSchedulerType = (scheduler: SchedulerType): string => {
  if (scheduler.roundRobin?.roster === true) {
    return "Roster";
  } else if (scheduler.roundRobin) {
    return "Round Robin";
  } else if (scheduler.groupScheduler) {
    return capitalize(scheduler.groupScheduler.type);
  } else {
    return "One-on-one";
  }
};
