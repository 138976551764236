import React, { useCallback, useEffect, useState } from "react";
import { Listbox as HUIListbox, Transition } from "@headlessui/react";
import classNames from "classnames";
import { twMerge } from "tailwind-merge";

import useDropdownPlacement from "@hooks/use-dropdown-placement";

import CloseIcon from "@components/Icons/CloseIcon";

import { Button } from "./Button";

const defaultColours = {
  selected: "bg-action-700",
  active: "bg-action-900",
  default: "bg-white",
};

export const useSimpleSearchProps = (
  options: any[],
  simpleSearchPlaceholder?: string,
  searchInputClassNames?: string
) => {
  const [searchKey, setSearchKey] = useState("");

  if (!simpleSearchPlaceholder || options.length < 5)
    return {
      filteredOptions: options,
    };

  return {
    filteredOptions: options.filter((option) => {
      if (!searchKey) return true;
      if (typeof option.title !== "string") return true;
      if (option.customSearchKey) {
        return option.customSearchKey
          .toLowerCase()
          .includes(searchKey.toLowerCase());
      }
      return option.title.toLowerCase().includes(searchKey.toLowerCase());
    }),
    searchProps: {
      placeholder: simpleSearchPlaceholder,
      onChange: setSearchKey,
      value: searchKey,
      searchInputClassNames,
    },
  };
};

export const ListboxOption = (props) => {
  const { primaryText, secondaryText, value, colors, children, className } =
    props;
  const mergedColours = { ...defaultColours, ...colors };

  return (
    <HUIListbox.Option {...{ value }}>
      {({ active, selected }) => (
        <div
          className={classNames(
            "cursor-default select-none relative py-4 pl-6 pr-4 border-b border-grey-900",
            selected
              ? mergedColours.selected
              : active
              ? mergedColours.active
              : mergedColours.default,
            className
          )}
        >
          {children ? (
            React.cloneElement(children, { selected })
          ) : (
            <>
              <div className="font-medium text-black">
                <span className={"block truncate"}>{primaryText}</span>
              </div>

              {secondaryText && (
                <div className={selected ? "text-action-400" : "text-grey-500"}>
                  <span className="block truncate">{secondaryText}</span>
                </div>
              )}
            </>
          )}
        </div>
      )}
    </HUIListbox.Option>
  );
};

const SearchField = ({
  name,
  value,
  onChange,
  placeholder,
  searchInputClassNames,
}) => {
  useEffect(() => {
    return () => {
      onChange("");
    };
  }, []);

  return (
    <div className="h-12 border-b border-b-grey-900">
      <input
        type="text"
        name={name}
        value={value}
        onChange={(e) => onChange(e.target.value)}
        placeholder={placeholder}
        onKeyDown={(e) => e.stopPropagation()} // prevent conflicts with Headless UI keyboard navigation
        ref={(input) => {
          input?.focus();
        }}
        className={classNames(
          "w-full bg-transparent h-full ring-none outline-none border-none focus:ring-0 text-sm px-6",
          searchInputClassNames
        )}
      />
    </div>
  );
};

const Listbox = (props) => {
  const {
    children,
    error,
    label,
    onChange,
    placeholder,
    value,
    clearable,
    readonly,
    className,
    wrapperOptionsClassName,
    customAddButton,
    customMenuClassNames,
    customMenuWrapperClassNames,
    optionsContentProps,
    customListboxClassNames,
    placeholderClassName,
    searchProps,
    noLabel = false,
  } = props;
  const renderClearButton = clearable && value;

  const clear = useCallback(() => onChange(null), []);

  const {
    setReferenceElement,
    setPopperElement,
    popper: { styles, attributes },
  } = useDropdownPlacement();

  return (
    <>
      <HUIListbox
        disabled={readonly}
        {...{ onChange, value }}
        as="div"
        className={classNames("space-y-1 w-full", className)}
      >
        {({ open }) => (
          <>
            {!noLabel && (
              <HUIListbox.Label className="block text-sm leading-5 text-grey-500">
                {label}
              </HUIListbox.Label>
            )}
            <div className="relative">
              <span
                className={classNames(
                  "inline-flex items-center justify-between w-full rounded-md shadow-sm p-0 border bg-white hover:bg-grey-950",
                  customListboxClassNames,
                  error
                    ? "border-red-300 hover:border-red-300 focus:border-red-300"
                    : "border-grey-900 hover:border-grey-950 focus:border-action-700",
                  readonly
                    ? "bg-grey-950/0"
                    : customAddButton
                    ? ""
                    : "form-select",
                  renderClearButton && "bg-none",
                  customAddButton && "border-0"
                )}
              >
                <HUIListbox.Button
                  className={twMerge(
                    "w-full cursor-default min-w-0 relative text-left focus:outline-none focus:shadow-none transition ease-in-out duration-150 sm:leading-6",
                    renderClearButton ? "pr-5" : "pr-10",
                    readonly && "text-grey-500",
                    customAddButton ? "py-0 pl-0 pr-0" : "py-2 pl-3",
                    placeholderClassName
                  )}
                  ref={setReferenceElement}
                >
                  <span className="block truncate">{placeholder}</span>
                </HUIListbox.Button>

                {renderClearButton && (
                  <Button className="mr-3" square circle small onClick={clear}>
                    <CloseIcon className="text-grey-500" />
                  </Button>
                )}
              </span>

              <Transition
                show={open}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
                className={classNames(
                  "absolute mt-0 w-full z-20 pb-10",
                  wrapperOptionsClassName
                )}
              >
                <div
                  className={classNames(
                    "shadow-lg rounded-lg bg-white",
                    customMenuClassNames
                  )}
                >
                  <HUIListbox.Options
                    ref={setPopperElement}
                    static
                    style={{
                      ...styles.popper,
                      left: 0,
                      right: 0,
                      margin: 0,
                    }}
                    className={classNames(
                      " rounded-md text-base leading-6 ring-1 ring-black/5 overflow-hidden focus:outline-none sm:text-sm sm:leading-5",
                      customMenuWrapperClassNames
                    )}
                    {...attributes.popper}
                  >
                    {searchProps && <SearchField {...searchProps} />}
                    <div
                      {...optionsContentProps}
                      className="overflow-auto max-h-60 "
                    >
                      {children}
                    </div>
                  </HUIListbox.Options>
                </div>
              </Transition>
            </div>
          </>
        )}
      </HUIListbox>

      {error?.message && (
        <div className="mt-2 p-3 text-sm rounded-md text-peach-600 bg-peach-950 flex flex-row items-center">
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M12.8716 5.54947C12.4893 4.86982 11.5107 4.86982 11.1284 5.54947L3.83825 18.5097C3.46329 19.1763 3.945 20 4.70983 20H19.2901C20.055 20 20.5367 19.1763 20.1617 18.5097L12.8716 5.54947ZM11 10.5C11 10.2239 11.2238 10 11.5 10H12.5C12.7761 10 13 10.2239 13 10.5V14.5C13 14.7761 12.7761 15 12.5 15H11.5C11.2238 15 11 14.7761 11 14.5V10.5ZM11 17C11 16.4477 11.4477 16 12 16C12.5523 16 13 16.4477 13 17C13 17.5523 12.5523 18 12 18C11.4477 18 11 17.5523 11 17Z"
            />
          </svg>
          <p id="email-error">{error.message}</p>
        </div>
      )}
    </>
  );
};

export default Listbox;
